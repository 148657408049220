import React, { useState, useEffect } from 'react';
import { Send, Download } from 'lucide-react';

const ChatbotPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isComplete, setIsComplete] = useState(false);

  const questions = [
    "What is your name?",
    "What is your zip code?",
    "What is your height?",
    "What is your weight?",
    "What is your ethnicity?",
  ];

  const questionKeys = ["name", "zipCode", "height", "weight", "ethnicity"];

  useEffect(() => {
    if (currentQuestion === 0) {
      setTimeout(() => {
        setMessages([{ text: "Welcome to TrialMatch AI! I'll ask you a few questions to help match you with suitable clinical trials. Let's begin!", sender: 'bot' }]);
      }, 500);
      setTimeout(() => {
        setMessages(prev => [...prev, { text: questions[currentQuestion], sender: 'bot' }]);
      }, 1500);
    }
  }, []);

  const handleSend = () => {
    if (input.trim()) {
      setMessages(prev => [...prev, { text: input, sender: 'user' }]);
      setAnswers(prev => ({ ...prev, [questionKeys[currentQuestion]]: input }));
      setInput('');

      setTimeout(() => {
        if (currentQuestion < questions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
          setMessages(prev => [...prev, { text: questions[currentQuestion + 1], sender: 'bot' }]);
        } else {
          setMessages(prev => [...prev, { text: "Thank you for providing your information. We'll use this to find suitable clinical trials for you. You can now download your information using the button below.", sender: 'bot' }]);
          setIsComplete(true);
        }
      }, 1000);
    }
  };

  const handleDownload = () => {
    const dataStr = JSON.stringify(answers, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    const exportFileDefaultName = 'user_data.json';

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="bg-blue-600 text-white py-4 px-6">
        <h1 className="text-2xl font-bold">TrialMatch AI Chatbot</h1>
      </header>
      <div className="flex-1 overflow-y-auto p-6">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`max-w-sm mb-4 p-3 rounded-lg ${
              message.sender === 'user' ? 'ml-auto bg-blue-500 text-white' : 'mr-auto bg-white'
            }`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <div className="bg-white p-4 border-t border-gray-200">
        {!isComplete ? (
          <div className="flex items-center">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              placeholder="Type your answer here..."
              className="flex-1 p-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={handleSend}
              className="bg-blue-500 text-white p-2 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <Send size={24} />
            </button>
          </div>
        ) : (
          <button
            onClick={handleDownload}
            className="w-full bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 flex items-center justify-center"
          >
            <Download size={24} className="mr-2" /> Download Your Information
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatbotPage;