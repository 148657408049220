import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Microscope, Users, Clipboard, Brain, ArrowRight, MessageSquare } from 'lucide-react';
import ChatbotPage from './ChatbotPage';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-blue-600">ClinicalTrialLink</h1>
          <nav>
            <ul className="flex space-x-4">
              <li><a href="#about" className="hover:text-blue-600">About</a></li>
              <li><a href="#trials" className="hover:text-blue-600">Clinical Trials</a></li>
              <li><a href="#services" className="hover:text-blue-600">Our Services</a></li>
              <li><a href="#contact" className="hover:text-blue-600">Contact</a></li>
            </ul>
          </nav>
        </div>
      </header>

      <main>
        <section className="bg-blue-600 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold mb-4">Revolutionizing Clinical Trials with AI</h2>
            <p className="text-xl mb-8">Empowering doctors and patients with intelligent trial matching and streamlined processes</p>
            <button className="bg-white text-blue-600 px-6 py-2 rounded-full font-semibold hover:bg-blue-100 transition duration-300">
              Learn More
            </button>
          </div>
        </section>

        <section id="about" className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center">About ClinicalTrialLink</h2>
            <div className="flex flex-wrap justify-center items-center">
              <div className="w-full md:w-1/2 p-4">
                <p className="text-lg">
                  At ClinicalTrialLink, we're dedicated to accelerating medical breakthroughs by optimizing the clinical trial process. Our AI-powered platform connects patients with suitable trials, assists researchers in participant selection, and streamlines trial management for healthcare professionals.
                </p>
              </div>
              <div className="w-full md:w-1/2 p-4 flex justify-center">
                <Brain size={120} className="text-blue-600" />
              </div>
            </div>
          </div>
        </section>

        <section id="trials" className="bg-gray-200 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center">Understanding Clinical Trials</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <Microscope className="mr-2" /> Phase 1: Safety
                </h3>
                <p>Initial testing on a small group to evaluate safety, determine safe dosage ranges, and identify side effects.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <Users className="mr-2" /> Phase 2: Efficacy
                </h3>
                <p>Testing on a larger group to assess effectiveness and further evaluate safety.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <Clipboard className="mr-2" /> Phase 3: Confirmation
                </h3>
                <p>Large-scale testing to confirm effectiveness, monitor side effects, and compare with standard treatments.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center">How We Help</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">For Patients</h3>
                <ul className="list-disc pl-5">
                  <li>Personalized trial recommendations based on medical history and preferences</li>
                  <li>Easy-to-understand information about trial processes and requirements</li>
                  <li>Ongoing support and updates throughout the trial participation</li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">For Doctors & Researchers</h3>
                <ul className="list-disc pl-5">
                  <li>AI-powered participant matching to improve trial success rates</li>
                  <li>Streamlined data collection and analysis tools</li>
                  <li>Real-time insights and predictive analytics for trial management</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="bg-blue-600 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold mb-8">Ready to Transform Clinical Trials?</h2>
            <p className="text-xl mb-8">Whether you're a patient looking for trials or a healthcare professional seeking to optimize your research, we're here to help.</p>
            <button className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-blue-100 transition duration-300 flex items-center mx-auto">
              Get Started <ArrowRight className="ml-2" />
            </button>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 ClinicalTrialLink. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};


const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100 text-gray-900">
        <header className="bg-white shadow-md">
          <div className="container mx-auto px-6 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-bold text-blue-600">ClinicalTrialLink</h1>
            <nav>
              <ul className="flex space-x-4">
                <li><Link to="/" className="hover:text-blue-600">Home</Link></li>
                <li><Link to="/#about" className="hover:text-blue-600">About</Link></li>
                <li><Link to="/#trials" className="hover:text-blue-600">Clinical Trials</Link></li>
                <li><Link to="/#services" className="hover:text-blue-600">Our Services</Link></li>
                <li><Link to="/chatbot" className="hover:text-blue-600 flex items-center">
                  <MessageSquare size={18} className="mr-1" /> Chatbot
                </Link></li>
              </ul>
            </nav>
          </div>
        </header>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/chatbot" element={<ChatbotPage />} />
        </Routes>

        <footer className="bg-gray-800 text-white py-6">
          <div className="container mx-auto px-6 text-center">
            <p>&copy; 2024 ClinicalTrialLink. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
};

export default App;